import React from 'react';
import Snowfall from 'react-snowfall';
// import image from public folder

/**
 * Used during the Bright Nights events in December/January to add a snowfall effect to some marketing slides.
 * Was shown on a screen at the Guest Service location
 */
export const NarrowCasting = ({ type }: { type: string }) => {
    return (
        type && (
            <div className="w-[1920px] h-[1080px] ">
                <Snowfall />
                {type === 'fastlane' && (
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/Narrowcasting%2Fnarrowcasting-fastlane.jpg?alt=media&token=2e31f871-1a9c-4eb7-ba5d-10f592f249ec"
                        alt="Fast Lane product info"
                    />
                )}
                {type === 'heights' && (
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/Narrowcasting%2Fnarrowcasting-heights.png?alt=media&token=ae32a3c8-c7af-4a94-bcdf-4f7afff3ad80"
                        alt="Height restrictions Walibi"
                    />
                )}
            </div>
        )
    );
};
