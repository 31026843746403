export const endpoints: {
    [park: string]: {
        staging: string;
        production: string;
        cache: string;
    };
} = {
    cawh: {
        staging: 'https://staging.walibifastlane.nl/api/api/guest/rides',
        production: 'https://www.walibifastlane.nl/api/api/guest/rides',
        cache: 'https://cache.walibifastlane.nl/api/api/guest/rides/__url__/images/0'
    },
    prmpg: {
        staging: 'https://mpg.staging.speedy-pass.com/api/api/guest/rides',
        production: 'https://mpg.speedy-pass.com/api/api/guest/rides',
        cache: 'https://mpg.speedy-pass.com/api/api/guest/rides/__url__/images/0'
    }
};
