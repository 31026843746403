import React from 'react';
import { WaitTimeScreenTable } from './WaitTimeScreenTable';

/**
 * Displaying the waiting time screen in portrait mode on top of a background image.
 * Used at various vertical screens in the park
 */
export const WaitTimeScreenTablePortrait = () => {
    return (
        <div
            className="w-[1080px] h-[1920px]"
            style={{
                backgroundImage: `url('/portrait.png')`,
                backgroundSize: 'cover'
            }}
        >
            <div className={`pt-[415px]`}>
                <WaitTimeScreenTable orientation="portrait" scale="scale-[120%]" />
            </div>
        </div>
    );
};
