import React, { useEffect } from 'react';
import { useWaitingTimes } from '../../../../../hooks/useWaitingTimes';
import { RideStateComponent } from '../../../../ride/RideStateComponent';
import { Ride } from '../../../../../types';
import { getParkAreaPrimaryColor, parkAreas } from '../../../../../data/parkAreas';
import { useParam } from '../../../../../hooks/useParam';
import { getRideAreaIDS } from '../../../../../utils/getRideAreaIDs';

const ParkAreaTitle = ({ line, areaId }: { line: string; areaId: string }) => {
    return (
        <div>
            <h1
                style={{
                    backgroundColor: getParkAreaPrimaryColor('cawh', areaId)
                }}
                className={`py-1 px-2 font-walibi uppercase text-white inline-block text-xl ml-12 mb-2`}
            >
                {line}
            </h1>
        </div>
    );
};

type AreaRide = {
    areaRideId: number;
    queryData: Ride;
};

const ParkArea = ({ areaId }: { areaId: string; titleFirstLine?: string; titleSecondLine?: string }) => {
    const isStaging = useParam('isStaging') === 'true';
    const isTestMode = useParam('isTestMode') === 'true';

    const ridesQuery = useWaitingTimes({
        isStaging,
        isTestMode,
        tags: ['wh_screen_allrides']
    });

    const [areaRides, setAreaRides] = React.useState<AreaRide[]>([]);

    useEffect(() => {
        // Set the area name based on the areaId
        let areaRidesUpdate: AreaRide[] = [];
        if (!ridesQuery.data) return;
        // Loop over all rides from the QSmart API and check if the ride is in the current area
        // Based on tags, which can be configure through the QSmart management console
        ridesQuery.data.forEach((ride) => {
            const locations = getRideAreaIDS(ride.tags);
            locations.forEach((location) => {
                if (location.areaId === areaId) {
                    areaRidesUpdate.push({
                        areaRideId: parseInt(location.areaRideId),
                        queryData: ride
                    });
                }
            });
        });

        // Sort the rides by areaRideId
        areaRidesUpdate.sort((a, b) => a.areaRideId - b.areaRideId);

        setAreaRides(areaRidesUpdate);
    }, [areaId, ridesQuery.data]);

    return (
        ridesQuery.isSuccess && (
            <div className="mb-12">
                {parkAreas['cawh'][areaId].lines?.map((line) => (
                    <ParkAreaTitle line={line} areaId={areaId} />
                ))}
                {areaRides.map((ride) => (
                    <div className="flex justify-between font-avenir_black text-xl my-2 items-center">
                        <div className="flex gap-[18px] items-center">
                            <span
                                style={{
                                    backgroundColor: getParkAreaPrimaryColor('cawh', areaId)
                                }}
                                className={`text-white w-8 flex text-sm items-center justify-center h-8 text-center font-walibi `}
                            >
                                <p>{ride.areaRideId}</p>
                            </span>
                            <div className="flex gap-4">
                                <p>{ride.queryData && ride.queryData.name}</p>
                                {ride.queryData?.tags?.includes('wh_fastlane') && <img alt="flame" className="h-4 mt-1" src="/fastlane-flame.png" />}
                            </div>
                        </div>
                        <div>
                            <div>
                                {ride.queryData.state === 'open' ? (
                                    <p className="bg-green-100 text-base py-1 px-4 rounded-full text-green-800">
                                        {ride.queryData?.tags?.includes('nowaittime') ? 'open' : `${ride.queryData.waitTimeMins} min`}
                                    </p>
                                ) : (
                                    <p className="bg-gray-200 text-base py-1 px-4 rounded-full text-gray-800">
                                        <RideStateComponent state={ride.queryData.state} openingTime={ride.queryData.openingTime} />
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    );
};

export const WaitTimeScreenTableAllRides = () => {
    return (
        <div
            className="w-[1920px] h-[1080px]"
            style={{
                backgroundImage: `url('/landscape-nofastlane.png')`,
                backgroundSize: 'cover'
            }}
        >
            <div className={`pt-[260px] scale-[105%] mx-auto gap-[86px] max-w-[1600px] grid grid-cols-3`}>
                <div>
                    <ParkArea areaId="01" />
                    <ParkArea areaId="02" />
                    <ParkArea areaId="03" />
                </div>
                <div>
                    <ParkArea areaId="04" titleFirstLine="Speed Zone" titleSecondLine="OFF ROAD" />
                    <ParkArea areaId="05" />
                    <ParkArea areaId="06" />
                </div>
                <div>
                    <ParkArea areaId="07" titleFirstLine="Walibi" titleSecondLine="Play Land" />
                    <ParkArea areaId="08" />
                </div>
            </div>
        </div>
    );
};
