import moment from 'moment';
import React, { createContext, useEffect, useState } from 'react';
import { themeSchedule } from '../data/themeSchedule';

export const ThemeContext = createContext<string>('default');

export const ThemeContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [theme, setCurrentTheme] = useState<string>('default');
    const themes = ['halloween', 'default'];

    const getThemeForToday = () => {
        const today = moment().format('YYYY-MM-DD');
        const themeToday = themeSchedule[today];
        return !themeToday || !themes.includes(themeToday) ? 'default' : themeToday;
    };

    useEffect(() => {
        // Set the initial theme
        setCurrentTheme(getThemeForToday());

        // Create an interval to check for date changes
        const intervalId = setInterval(() => {
            setCurrentTheme(getThemeForToday());
        }, 60 * 1000); // Check every minute

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};
