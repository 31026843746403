import { useContext } from 'react';
import { getLocalization } from '../../utils/getLocalization';
import { LocalizationContext } from '../../context/LocalizationContext';
import moment from 'moment';

interface RideStateComponentProps {
    state: string;
    openingTime?: string | null;
}

export const RideStateComponent = ({ state, openingTime }: RideStateComponentProps) => {
    const currentLanguage = useContext(LocalizationContext);
    let localization = getLocalization(openingTime && state === 'full_and_closed' ? 'openingTime' : state, currentLanguage);
    if (openingTime) localization = localization.replace('{time}', moment.parseZone(openingTime).format('HH:mm'));
    return localization;
};
