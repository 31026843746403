export const themeSchedule: {
    [date: string]: string;
} = {
    '2024-10-05': 'halloween',
    '2024-10-06': 'halloween',
    '2024-10-07': 'halloween',
    '2024-10-08': 'default',
    '2024-10-09': 'halloween',
    '2024-10-10': 'halloween',
    '2024-10-11': 'halloween',
    '2024-10-12': 'halloween',
    '2024-10-13': 'halloween',
    '2024-10-14': 'halloween',
    '2024-10-15': 'halloween',
    '2024-10-16': 'halloween',
    '2024-10-17': 'halloween',
    '2024-10-18': 'halloween',
    '2024-10-19': 'halloween',
    '2024-10-20': 'halloween',
    '2024-10-25': 'halloween',
    '2024-10-26': 'halloween',
    '2024-10-27': 'halloween',
    '2024-10-31': 'halloween',
    '2024-11-01': 'halloween',
    '2024-11-02': 'halloween',
    '2024-11-03': 'halloween',
    '2024-12-09': 'halloween',
    '2024-12-10': 'halloween',
    '2024-12-11': 'halloween',
    '2024-12-12': 'halloween',
    '2024-12-13': 'halloween',
    '2024-12-14': 'halloween',
    '2024-12-15': 'halloween',
    '2024-12-16': 'halloween',
    '2024-12-17': 'halloween',
    '2024-12-18': 'halloween',
    '2024-12-19': 'halloween',
    '2024-12-20': 'halloween',
    '2024-12-21': 'halloween',
    '2024-12-22': 'halloween',
    '2024-12-23': 'halloween',
    '2024-12-24': 'halloween',
    '2024-12-25': 'halloween',
    '2024-12-26': 'halloween',
    '2024-12-27': 'halloween',
    '2024-12-28': 'halloween',
    '2024-12-29': 'halloween',
    '2024-12-30': 'halloween',
    '2024-12-31': 'halloween',
    '2025-01-01': 'halloween',
    '2025-01-02': 'halloween',
    '2025-01-03': 'halloween',
    '2025-01-04': 'halloween',
    '2025-01-05': 'halloween'
};
