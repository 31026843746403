import React, { useContext, useEffect, useState } from 'react';
import { useParam } from '../../../../../hooks/useParam';
import { getParkAreaPrimaryColor } from '../../../../../data/parkAreas';
import { useWaitingTimes } from '../../../../../hooks/useWaitingTimes';
import { rideImages } from '../../../../../data/rideImages';
import { Ride, RideStateType } from '../../../../../types';
import { RideStateComponent } from '../../../../ride/RideStateComponent';
import { getRideAreaIDS } from '../../../../../utils/getRideAreaIDs';
import { ErrorDisplay } from '../../../../ride/ErrorDisplay';
import { ThemeContext } from '../../../../../context/ThemeContext';

/**
 * Template for waiting time screen in table format.
 * Rides are displayed in two columns (or in a single one when orientation isn't landscape), with their name, location, park map number, wait time and status.
 */
export const WaitTimeScreenTable = ({ orientation = 'landscape', scale = '' }) => {
    const isTestMode = useParam('isTestMode') === 'true';
    const isStaging = useParam('isStaging') === 'true';
    const sortMode = 'waitTime';
    const ridesQuery = useWaitingTimes({
        isTestMode,
        isStaging,
        sortMode,
        endpoint: 'cawh',
        tags: ['wh_screen_table_park']
    });
    const theme = useContext(ThemeContext);

    return (
        <>
            {ridesQuery.isSuccess && (
                <div className={`${theme === 'halloween' && 'text-white'} min-h-screen`}>
                    <div className={`flex justify-center`}>
                        <div className={`${orientation === 'landscape' && `grid-cols-2`} inline-grid gap-x-16 ${scale}`}>
                            {ridesQuery.data.map((ride: Ride) => (
                                <Attraction
                                    key={ride.name}
                                    rideId={ride.id}
                                    rideThroughput={ride.throughput}
                                    rideTags={ride.tags}
                                    areaName={ride.location}
                                    rideName={ride.name}
                                    rideOpeningTime={ride.openingTime}
                                    rideImage={rideImages['cawh'][`${theme === 'halloween' ? 'hfn-thumbs' : 'default'}`][ride.name]}
                                    rideStatus={{
                                        waitTimeMins: ride.waitTimeMins,
                                        state: ride.state
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    {(isTestMode || isStaging) && (
                        <div className="flex justify-center scale-[200%] font-avenir_black">
                            <p className="bg-gray-300 text-gray-800 py-1 px-4 mt-12 rounded-xl text-center inline-block">MAINTENANCE MODE ENABLED</p>
                        </div>
                    )}
                </div>
            )}
            {ridesQuery.isError && <ErrorDisplay />}
        </>
    );
};

const Attraction = ({
    rideName,
    rideId,
    rideThroughput,
    rideTags,
    rideImage,
    areaName,
    rideStatus,
    rideOpeningTime
}: {
    rideName: string;
    rideId: string;
    rideThroughput: number;
    rideTags: string[];
    rideImage: string;
    areaName: string;
    rideOpeningTime: string | null;
    rideStatus: { state: RideStateType; waitTimeMins: number };
}) => {
    const theme = useContext(ThemeContext);

    const [areaRideId, setAreaRideId] = useState<string>('');
    const [areaId, setAreaId] = useState<string>('?');

    useEffect(() => {
        console.log(rideTags);
        const locations = getRideAreaIDS(rideTags);
        setAreaRideId(locations[0]?.areaRideId ?? '?');
        setAreaId(locations[0]?.areaId ?? '?');
    }, [rideTags]);

    return (
        <div className="w-[640px] flex mb-4 border-b-[1px] pb-4 items-center">
            <div
                style={{ backgroundImage: `url(${rideImage})` }}
                className={`bg-cover w-16 h-16 mr-6 rounded-full bg-slate-200 justify-self-center`}
            />
            <div>
                <h1 className="font-walibi text-2xl uppercase">{rideName}</h1>
                <div className={`text-xl font-avenir items-center  flex ${theme === 'halloween' && `text-white`}`}>
                    <div className="flex gap-2 items-center">
                        <span
                            style={{ backgroundColor: getParkAreaPrimaryColor('cawh', areaId) }}
                            className={`text-white scale-90 w-6 text-sm flex items-center justify-center h-6 text-center font-walibi`}
                        >
                            <p>{parseInt(areaRideId)}</p>
                        </span>
                        <div className="flex gap-4">
                            <p className={`${theme === 'halloween' ? 'text-gray-300' : `text-gray-600`}`}>{areaName}</p>
                            {/* <RideStateTrains rideState={rideStatus.state} rideTags={rideTags} rideNoTrains={0} rideId={rideId} /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="ml-auto">
                {rideStatus.state !== 'open' ? (
                    <div
                        className={`text-center text-xl font-avenir_black 
                        ${theme === 'halloween' ? `text-gray-100 bg-gray-800` : `text-gray-800 bg-gray-100`}
                    py-2 px-4 rounded-full`}
                    >
                        <RideStateComponent state={rideStatus.state} openingTime={rideOpeningTime} />
                    </div>
                ) : (
                    <p
                        className={`mb-2 text-2xl font-avenir_black text-center  ${
                            theme !== 'halloween' && 'shadow-md'
                        } shadow-green-50 py-2 px-4 rounded-full mt-2
                        
                        ${theme === 'halloween' ? `text-green-200 bg-green-800` : `text-green-800 bg-green-100`}
                        
                        `}
                    >
                        {rideStatus.waitTimeMins} min
                    </p>
                )}
            </div>
        </div>
    );
};
