type Location = {
    areaId: string;
    areaRideId: string;
}[];

export function getRideAreaIDS(tags: string[]): Location {
    let locations: Location = [];
    tags.forEach((tag) => {
        // Split the tag into components. Area indicator is formatted as `wh_area-<areaId>-<areaRideId>`
        let tagComponents = tag.split('-');
        // If tag is area indicator, it has 3 components and starts with `wh_area`
        if (tagComponents[0] === 'wh_area') {
            locations.push({
                areaId: tagComponents[1],
                areaRideId: tagComponents[2]
            });
        }
    });
    return locations;
}
