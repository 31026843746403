export const localizations: {
    [language in 'NL' | 'EN']: {
        [field: string]: string;
    };
} = {
    NL: {
        closed_indefinitely: 'Vandaag gesloten',
        full_and_closed: 'Tijdelijk gesloten',
        full: 'Wachtrij gesloten',
        stopped: 'Gesloten',
        closed: 'Gesloten',
        not_operational: 'In onderhoud',
        default: 'Gesloten',
        openingTime: 'Opent om {time}',
        trains: ' van de '
    },
    EN: {
        closed_indefinitely: 'Closed today',
        not_operational: 'In maintenance',
        full_and_closed: 'Closed temporarily',
        stopped: 'Closed',
        closed: 'Closed',
        full: 'Queue closed',
        default: 'Closed',
        openingTime: 'Opens at {time}',
        trains: ' of the '
    }
};
