import React, { createContext, useEffect, useState } from 'react';

export const LocalizationContext = createContext<'EN' | 'NL'>('EN');

/**
 * Used to provide the current language to all components.
 * Switches between English and Dutch every 3.5 seconds.
 */
export const LocalizationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentLanguage, setCurrentLanguage] = useState<'EN' | 'NL'>('EN');

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentLanguage((prevLanguage) => (prevLanguage === 'EN' ? 'NL' : 'EN'));
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    return <LocalizationContext.Provider value={currentLanguage}>{children}</LocalizationContext.Provider>;
};
