import React from 'react';
import { useStock } from '../../../../hooks/useStock';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment/moment';
import { useParam } from '../../../../hooks/useParam';

/**
 * Used during Halloween Fright Nights to display live stock information for the various haunted houses tickets.
 * Was displayed on a screen at the Guest Service location
 */
export const HalloweenStock = () => {
    return (
        <div className="bg-black p-4 text-white text-5xl font-avenir_black">
            <Category>
                <Product defaultPrice={1000} label="The Final Slay Ride" />
                <Product defaultPrice={1000} label="Jefferson Manor" />
                <Product defaultPrice={1000} label="Psychoshock" />
                <Product defaultPrice={1000} label="The Villa" />
            </Category>
            <Category>
                <Product defaultPrice={2000} label="Below" />
                <Product defaultPrice={2500} label="Slaughterhouse" />
                <Product defaultPrice={2250} label="The Clinic" />
                <Product defaultPrice={2000} label="Urban Explhorror" />
            </Category>
            <Category>
                <Product defaultPrice={4500} label="R.I.P. Pass" name="RIP Pass" />
                <Product defaultPrice={2800} label="Haunted Package" />
            </Category>
            <Category>
                <Product defaultPrice={1000} label="Camp of Curiosities" />
                <Product defaultPrice={1600} label="Wicked Woods" />
            </Category>
        </div>
    );
};

const Product = ({ label, name, defaultPrice }: { label: string; name?: string; defaultPrice: number }) => {
    if (!name) name = label;
    let date = useParam('date');
    const today = moment().format('YYYY-MM-DD');
    if (date == null) date = today;
    const { data } = useStock(date);
    const stock = data;
    const [soldOut, setSoldOut] = useState(false);
    const formatPrice = (price: number) => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price / 100);
    const [price] = useState(formatPrice(defaultPrice));
    useEffect(() => {
        if (stock == null) return;
        // @ts-ignore
        if (!stock.map((s) => s.name).includes(name)) {
            setSoldOut(true);
            return;
        }
    }, [stock, name, defaultPrice]);

    return (
        <>
            <p>{label}</p>
            {soldOut ? (
                <div className="relative">
                    <p className="bg-[#980002] w-48 text-center font-walibi text-2xl py-2 block scale-[140%]">SOLD OUT</p>
                </div>
            ) : (
                <p className="text-center justify-center">
                    {/* {price === "" ? "?" : <span>{price.substring(0, price.length-2)}<sup >{price.substring(price.length -2)}</sup></span>} */}
                    {price}
                </p>
            )}
        </>
    );
};

const Category = ({ children }: { children: React.ReactNode }) => {
    return <div className="grid grid-cols-[500px,200px] mb-40 gap-y-8">{children}</div>;
};
