import { useQuery } from 'react-query';
import axios from 'axios';

export type Stock = {
    name: string;
    label: string;
    price: number;
};

const fetchStockData = async (date: string) => {
    const { data } = await axios({
        url: `https://ticketing-api.walibi.nl/api/products?hfn=1&lang=nl&date=${date}`,
        method: 'GET',
        headers: { accept: 'application/json' }
    });

    return data.map((d: { name: string; price: number }) => {
        return { name: d.name, label: '', price: d.price };
    });
    // return data.map(d => d.name);
};

const useStock = (date: string) => {
    return useQuery('stock', () => fetchStockData(date), {
        refetchInterval: 60000
    });
};

export { useStock };
