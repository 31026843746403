import React, { useContext } from 'react';
import { useWaitingTimes } from '../../../../../hooks/useWaitingTimes';
import { useParam } from '../../../../../hooks/useParam';
import { rideImages } from '../../../../../data/rideImages';
import { RideStateComponent } from '../../../../ride/RideStateComponent';
import { Ride } from '../../../../../types';
import { ErrorDisplay } from '../../../../ride/ErrorDisplay';
import { ThemeContext } from '../../../../../context/ThemeContext';

/**
 * Waiting time screen with large ride images and overlay with ride name and waittime/status.
 * Is currently live at the Guest Service location on a big TV screen.
 */
export const WaitTimeScreenGrid = () => {
    const theme = useContext(ThemeContext);
    const isTestMode = useParam('isTestMode') === 'true';
    const isStaging = useParam('isStaging') === 'true';
    const ridesQuery = useWaitingTimes({
        isTestMode,
        isStaging,
        endpoint: 'cawh',
        skipNotOperational: true,
        tags: ['wh_screen_grid'],
        sortMode: 'waitTimeReversed'
    });
    return (
        <>
            {ridesQuery.isSuccess && (
                <div>
                    <div className={`grid w-[1920px] h-[1080px] grid-cols-${Math.ceil(ridesQuery.data.length / 2)}`}>
                        {ridesQuery.data.map((ride: Ride) => (
                            <div>
                                <Attraction
                                    openingTime={ride.openingTime}
                                    name={ride.name}
                                    rideTags={ride.tags}
                                    image={rideImages['cawh'][theme === 'halloween' ? 'hfn' : 'default'][ride.name]}
                                    waitTimeMins={ride.waitTimeMins}
                                    state={ride.state}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {ridesQuery.isError && <ErrorDisplay />}
        </>
    );
};

const Attraction = ({
    name,
    image,
    waitTimeMins,
    rideTags,
    state,
    openingTime,
    rideWithLights = false
}: {
    name: string;
    image: string;
    rideTags?: string[];
    waitTimeMins: number;
    state: string;
    openingTime: string | null;
    rideWithLights?: boolean;
}) => {
    return (
        <div
            className="h-[540px] relative"
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="absolute w-full h-full bg-gradient-to-t from-black via-transparent to-transparent" />
            <div className="flex flex-col h-full p-8 text-white relative">
                {rideWithLights && (
                    <div className="flex gap-2 items-center">
                        <div className="bg-slate-800 rounded-full p-2 border-white border-solid border-[1px] scale-75">
                            <img className="h-6" src={'./rideWithLights.svg'} alt="" />
                        </div>
                        <p className="font-walibi uppercase">Ride with lights</p>
                    </div>
                )}
                {/* {rideTags?.includes('wh_fastlane') && <img src="/fastlane-flame.png" alt="fl" className="max-w-[60px]" />} */}
                <h1 className="font-walibi uppercase text-4xl mt-auto mb-1">{name}</h1>
                {state === 'open' ? (
                    <p className="font-avenir_black text-3xl">{Math.max(5, waitTimeMins)} min</p>
                ) : (
                    <p className="font-avenir_black text-3xl">
                        <RideStateComponent state={state} openingTime={openingTime} />
                    </p>
                )}
            </div>
        </div>
    );
};
