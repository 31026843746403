import React from 'react';

export const ErrorDisplay = () => {
    return (
        <div className="flex flex-col gap-4 text-center justify-center">
            <p className="text-4xl mt-32 font-walibi text-red-500 uppercase">Wachttijden tijdelijk niet beschikbaar</p>
            <p className="text-3xl text-gray-400 font-avenir_black">Waitting times temporarily not available</p>
        </div>
    );
};
